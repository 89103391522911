const styles = {
  global: {
    'html, body, #root': {
      height: '100%',
      color: 'solid.black',
    },
  },
};

export default styles;
