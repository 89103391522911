import Avatar from './Avatar';
import Button from './Button';
import Input from './Input';
import Text from './Text';

const components = {
  Avatar,
  Button,
  Input,
  Text,
};

export default components;
